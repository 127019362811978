import React, { useRef, useState } from "react";
import {
  Footer,
  Header,
  Overlay,
  Content,
  Title,
  ContentTitle,
  ContentDescription,
  Wrapper,
  InputWrapper,
  Action,
  CloseWrapper,
  SuccessTitle,
  StatusContent,
  SuccessDescription,
  InputContainer,
} from "./Join.style";
import { isMobile } from "react-device-detect";
import Button from "../../shared/button/Button";
import Input from "../../shared/input/Input";
import { Formik, Form, Field, FormikProps } from "formik";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as StatusIcon } from "../../assets/icons/statusIcon.svg";

interface IProps {
  onClose: () => void;
}

const Join = ({ onClose }: IProps) => {
  const formikRef = useRef<FormikProps<{
    name: string;
    number: string;
  }> | null>(null);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);

  const handleSubmit = async ({
    name,
    number,
  }: {
    number: string;
    name: string;
  }) => {
    setLoading(true);
    setError(false);

    try {
      const response = await fetch(
        "https://api.freebusiness.ge/Core/BusinessRequest/Add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: "",
            businessName: name,
            mobile: number,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      await response.json();
    } catch (err) {
      setError(true);
    } finally {
      setStatusSuccess(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <Wrapper isMobile={isMobile}>
        <Header>
          <Title>შემოუერთდი</Title>
          <CloseWrapper onClick={onClose}>
            <Close />
          </CloseWrapper>
        </Header>
        {!statusSuccess ? (
          <Content>
            <ContentTitle>მინდა შევუერთდე</ContentTitle>
            <ContentDescription>
              შემოერთებისთვის მოგვწერეთ კომპანიის დასახელება და საკონტაქტო
              ნომერი
            </ContentDescription>
            <InputContainer>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  name: "",
                  number: "",
                }}
                onSubmit={({ name, number }) => {
                  handleSubmit({
                    name,
                    number,
                  });
                }}
              >
                {() => (
                  <Form>
                    <Action>
                      <InputWrapper>
                        <Field
                          name="name"
                          label="კომპანიის დასახელება "
                          component={Input}
                        />
                        <Field
                          name="number"
                          label="საკონტაქტო ნომერი "
                          component={Input}
                        />
                      </InputWrapper>
                    </Action>
                  </Form>
                )}
              </Formik>
            </InputContainer>
          </Content>
        ) : (
          <StatusContent>
            <StatusIcon />
            <SuccessTitle>მოთხოვნა წარმატებით გაიგზავნა</SuccessTitle>
            <SuccessDescription>
              დამატებით დეტალებზე დაგიკავშირდებით
            </SuccessDescription>
          </StatusContent>
        )}
        <Footer>
          {!statusSuccess ? (
            <Button
              widthType="fit-content"
              isLoading={loading}
              type="green"
              content="გაგზავნა"
              onClick={() => {
                formikRef.current?.submitForm();
              }}
            />
          ) : (
            <Button
              widthType="fit-content"
              isLoading={loading}
              type="white"
              content="დახურვა"
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Footer>
      </Wrapper>
    </>
  );
};

export default Join;
