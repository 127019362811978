import React, { ReactNode } from "react";
import {
  Wrapper,
  IconWrapper,
  Text,
  Quantity,
  ContentWrapper,
  BannerContainer,
  TextContainer,
  GrayText,
  BlackText,
} from "./CardComponent.style";
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";

interface IProps {
  icon: ReactNode;
  title: string;
  quantity: number;
  isActive?: boolean;
  hasWarning?: boolean;
  onClick?: () => void;
}

const CardComponent = ({
  icon,
  title,
  hasWarning,
  quantity,
  isActive,
  onClick,
}: IProps) => {
  return (
    <Wrapper
      onClick={onClick}
      style={{
        cursor: onClick ? "pointer" : "default",
        background: isActive ? "#CDF1DC" : "transparent",
      }}
      role={onClick ? "button" : undefined}
      aria-pressed={isActive}
      tabIndex={onClick ? 0 : undefined}
    >
      <IconWrapper>{icon}</IconWrapper>
      <ContentWrapper>
        <Text>{title}</Text>
        {!hasWarning ? (
          <Quantity>{quantity}</Quantity>
        ) : (
          <BannerContainer>
            <Warning height={16} width={16} />
            <TextContainer>
              <GrayText>სია გამოქვეყნდება:</GrayText>
              <BlackText>19 დეკემბერი, 13:00 სთ</BlackText>
            </TextContainer>
          </BannerContainer>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

// Default props for fallback
CardComponent.defaultProps = {
  onClick: undefined,
};

export default CardComponent;
