import React from "react";
import logo from "../../assets/icons/logo.png";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedIn.svg";
import {
  DescriptionContainer,
  FooterWrapper,
  IconsWrapper,
  Wrapper,
} from "./Footer.style";

const Footer = () => {
  return (
    <Wrapper>
      <FooterWrapper>
        <DescriptionContainer>
          <img style={{ width: "182px" }} src={logo} alt="" />
          <p>თავისუფალი ბიზნესების პლატფორმა</p>
        </DescriptionContainer>
        <IconsWrapper>
          <a
            target="_blank"
            href="https://www.facebook.com/freebusinessgeorgia"
          >
            <Facebook />
          </a>

          <a target="_blank" href="https://www.linkedin.com/company/105769129">
            <LinkedIn />
          </a>
          <a target="_blank" href="https://instagram.com/free_business_georgia">
            <Instagram />
          </a>
        </IconsWrapper>
      </FooterWrapper>
    </Wrapper>
  );
};

export default Footer;
