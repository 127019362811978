import React, { useRef, useState } from "react";
import {
  Footer,
  Header,
  Overlay,
  Content,
  Title,
  ContentTitle,
  ContentDescription,
  Wrapper,
  InputWrapper,
  Action,
  CloseWrapper,
  SuccessTitle,
  SuccessContent,
  SuccessDescription,
  InputContainer,
} from "./Request.style";
import Button from "../../shared/button/Button";
import Input from "../../shared/input/Input";
import { Formik, Form, Field, FormikProps } from "formik";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as StatusIcon } from "../../assets/icons/statusIcon.svg";

const Request = ({ onClose }: { onClose: () => void }) => {
  const formikRef = useRef<FormikProps<{
    name: string;
  }> | null>(null);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState("");
  const [statusSuccess, setStatusSuccess] = useState(false);

  const handleSubmit = async ({ name }: { name: String }) => {
    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        "https://api.freebusiness.ge/Core/UserRequestsBusiness/Request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessName: name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
      setStatusSuccess(true);
    }
  };
  return (
    <>
      <Overlay onClick={onClose} />
      <Wrapper>
        <Header>
          <Title>შემოუერთდი</Title>
          <CloseWrapper onClick={onClose}>
            <Close />
          </CloseWrapper>
        </Header>
        {!statusSuccess ? (
          <Content>
            <ContentTitle>მოითხოვე პოზიციის დაფიქსირება</ContentTitle>
            <ContentDescription>
              ჩაწერეთ იმ კომპანიის დასახელება, რომლის პოზიციის დაფიქსირებაც
              გსურთ
            </ContentDescription>
            <InputContainer>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  name: "",
                }}
                onSubmit={({ name }: any) => {
                  handleSubmit({
                    name,
                  });
                }}
              >
                {() => (
                  <Form>
                    <Action>
                      <InputWrapper>
                        <Field
                          name="name"
                          label="კომპანიის დასახელება "
                          component={Input}
                        />
                      </InputWrapper>
                    </Action>
                  </Form>
                )}
              </Formik>
            </InputContainer>
          </Content>
        ) : (
          <SuccessContent>
            <StatusIcon />
            <SuccessTitle>მოთხოვნა წარმატებით გაიგზავნა</SuccessTitle>
            <SuccessDescription>
              დამატებით დეტალებზე დაგიკავშირდებით
            </SuccessDescription>
          </SuccessContent>
        )}
        <Footer>
          {!statusSuccess ? (
            <Button
              isLoading={loading}
              widthType="fit-content"
              type="green"
              content="გაგზავნა"
              onClick={() => {
                formikRef.current?.submitForm();
              }}
            />
          ) : (
            <Button
              isLoading={loading}
              widthType="fit-content"
              type="white"
              content="დახურვა"
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Footer>
      </Wrapper>
    </>
  );
};

export default Request;
