import HomePage from "./page/homePage/HomePage";
import "./app.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="wrapper">
              <HomePage />
            </div>
          }
        />
        <Route
          path="/agenda"
          element={
            <div style={{ maxWidth: "100%", display: "flex" }}>
              <img
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                  height: "auto", // Maintain aspect ratio
                }}
                src="./agenda.png"
                alt="agenda"
              />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
