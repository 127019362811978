import styled, { css } from "styled-components";

const borderColor = (isFocus, invalid, hasValue) => {
  if (invalid) {
    return "#D80027";
  } else if (isFocus) {
    return "#65656533";
  } else if (hasValue) {
    return "#65656533";
  } else if (!hasValue) {
    return "#65656533";
  } else {
    return null;
  }
};

export const Box = styled.div`
  position: relative;
`;

export const Label = styled.label(
  ({ isfocus, invalid, hasValue }) => css`
    font-size: 12px;
    position: absolute;
    padding: 4px;
    top: 20px;
    left: 15px;
    z-index: 1;
    color: ${invalid ? "#D80027" : isfocus ? "#888888" : "#888888"};
    cursor: text;
    transition: all 0.2s;
    ${(hasValue || isfocus) &&
    css`
      font-size: 13px;
      top: -10px;
    `}

    &::before {
      box-sizing: border-box;
      content: "";
      display: block;
      position: absolute;
      pointer-events: none;
      top: 11px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: inherit;
      margin: calc(1 * -1px);
      background-color: ${invalid
        ? "#D80027"
        : isfocus
        ? "#F7F9FC"
        : "#F7F9FC"};
    }
  `
);

export const CustomInput = styled.input(
  ({ disabled, invalid, isfocused, hasValue }) => css`
    font-size: 12px;
    line-height: 20px;
    font-family: "HelveticaNeue-normal", Arial, sans-serif;
    width: 100%;
    padding: 17px 15px;
    border-radius: 8px;
    letter-spacing: 1px;
    outline: none;
    z-index: 1;
    background-color: ${invalid ? "#fce8ea" : "#F7F9FC"};
    border: 1px solid ${borderColor(isfocused, invalid, hasValue)};
    color: ${invalid ? "#D80027" : "#000"};
    ${disabled &&
    css`
      color: '#9298A6'
      cursor: not-allowed;
    `}
  `
);

export const ErrorMassage = styled.p(
  ({ theme }) => css`
    color: #d80027;
    font-family: "HelveticaNeue-normal", Arial, sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-top: 12px;
  `
);
