import React, { useState } from "react";
import Information from "../../components/information/Information";
import GridSection from "../../components/gridSection/GridSection";
import Join from "../../components/join/Join";
import Request from "../../components/request/Request";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const HomePage = () => {
  const [openJoin, setOpenJoin] = useState(false);
  const [openRequest, setOpenRequest] = useState(false);

  return (
    <>
      <Header
        handleJoin={() => {
          setOpenJoin(true);
        }}
      />

      <Information
        handleJoin={() => {
          setOpenJoin(true);
        }}
        handleRequest={() => {
          setOpenRequest(true);
        }}
      />
      <GridSection
        handleJoin={() => {
          setOpenJoin(true);
        }}
      />
      {openJoin && (
        <Join
          onClose={() => {
            setOpenJoin(false);
          }}
        />
      )}
      {openRequest && (
        <Request
          onClose={() => {
            setOpenRequest(false);
          }}
        />
      )}
      <Footer />
    </>
  );
};

export default HomePage;
