import React from "react";
import { Wrapper } from "./Button.style";

interface ButtonProps {
  size?: "small" | "normal";
  widthType?: "full" | "fit-content";
  content: string;
  type?: "green" | "white";
  isLoading?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  size = "normal",
  widthType = "full",
  content,
  type = "white",
  isLoading = false,
  onClick,
}) => {
  return (
    <Wrapper size={size} widthType={widthType} onClick={onClick} type={type}>
      {isLoading ? "...loading" : content}
    </Wrapper>
  );
};

export default Button;
