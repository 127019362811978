import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 33px;
  display: flex;
  justify-content: center;
  gap: 50px;
  border-top: 1px solid #e9e9e9;
`;

export const FooterWrapper = styled.div`
  display: flex;
  ${isMobile && "flex-direction: column;"}
  align-items: ${isMobile ? "start" : "center"};
  ${isMobile && "gap: 32px"};
  justify-content: space-between;
  max-width: 1300px;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 650px;
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  & svg {
    cursor: pointer;
  }
`;
