import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    z-index: 1000;
    position: fixed;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    border-radius: 12px;
    background: white;
    width: ${isMobile ? "93%" : "100%"};
    max-width: 528px;
  `
);

// Overlay Component
export const Overlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0707074d;
    opacity: 0.5;
    z-index: 999;
  `
);

export const Header = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(101, 101, 101, 0.08);
    padding: 18px 24px;
  `
);
// Title Component
export const Title = styled.div(
  ({ theme }) => css`
    line-height: normal;
    font-size: 14px;
    font-family: "HelveticaNeue-normal", Arial, sans-serif;
  `
);

// Icon Wrapper Component
export const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${isMobile &&
  css`
    padding: 0 24px;
  `}
`;

export const StatusContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
`;

export const SuccessTitle = styled.div`
  padding-top: 16px;
  color: #383838;
  font-size: 16px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const SuccessDescription = styled.div`
  padding-top: 8px;
  color: #383838;
  font-size: 16px;
`;

export const ContentTitle = styled.div`
  margin-top: 32px;
  font-size: 14px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const ContentDescription = styled.div`
  margin-top: 6px;
  font-size: 12px;
  max-width: 250px;
  line-height: 18px;
  text-align: center;
`;

export const Action = styled.div`
  margin: 24px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 590px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: auto;
  max-width: 400px;
  width: 100%;
`;

// Footer Component
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(101, 101, 101, 0.08);
  padding: 12px 24px;
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
`;

export const InputContainer = styled.div`
  width: 100%;
`;
