import React from "react";
import { isMobile } from "react-device-detect";

import {
  Wrapper,
  ContentContainer,
  HeaderWrapper,
  LogoWrapper,
  Title,
  SubTitle,
  Description,
  ButtonContainer,
  EuropeFlagWrapper,
  GeorgianFlagWrapper,
  GeorgianFlagBackground,
  GreenBackground,
  EuropeFlagBackground,
  WarningText,
  BlackText,
} from "./Information.style";
import Button from "../../shared/button/Button";
import { ReactComponent as Flags } from "../../assets/icons/flags.svg";
import EuropeFlag from "../../assets/icons/europe.png";
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";

import GeorgianFlag from "../../assets/icons/georgianFlag.png";

const Information = ({ handleJoin, handleRequest }: any) => {
  return (
    <Wrapper>
      <EuropeFlagWrapper>
        <img src={EuropeFlag} alt="" />
      </EuropeFlagWrapper>
      <EuropeFlagBackground />
      <ContentContainer>
        <LogoWrapper>
          <Flags />
        </LogoWrapper>
        <HeaderWrapper>
          <Title>თავისუფალი</Title>
          <SubTitle>ბიზნესების განცხადება</SubTitle>
        </HeaderWrapper>
        <Description>
          არსებული კრიზისი გაცდა პარტიული პოლიტიკის საზღვრებს და გარდაუვალი
          სოციალური და ეკონომიკური საფრთხის წინაშე აყენებს ქვეყანას. აუცილებელია
          ვითარების სასწრაფო დეესკალაცია. დეესკალაციის ყველაზე პრაგმატული გზა
          არის ახალი არჩევნების დანიშვნა და ბოლო პროცესების დროს დაპატიმრებული
          ადამიანების ახალ წლამდე გათავისუფლება. ჩვენ, ქართული ბიზნესის
          წარმომადგენლები, ვართ რა პასუხისგებელნი ქვეყნის ბედზე და მის შეუქცევად
          განვითარებაზე, განურჩევლად ჩვენი პოლიტიკური პრეფერენციებისა, ხელს
          ვაწერთ ამ მოწოდებას და განცხადებას.
        </Description>
        <WarningText>
          <div>
            <Warning height={16} width={16} />
          </div>
          <BlackText>
            თავისული ბიზნესების განცხადებაზე შემოერთების ვადა ამოიწურა
            31/12/2024 18:00 საათზე!
          </BlackText>
        </WarningText>
      </ContentContainer>
      {/* <ButtonContainer>
        <Button
          widthType={isMobile ? "full" : "fit-content"}
          type="green"
          content="შეუერთდი"
          onClick={handleJoin}
        />
        <Button
          type="white"
          content="მოითხოვე კომპანიის პოზიცია"
          onClick={handleRequest}
        />
      </ButtonContainer> */}
      <GeorgianFlagBackground />
      <GeorgianFlagWrapper>
        <img src={GeorgianFlag} alt="" />
      </GeorgianFlagWrapper>
      <GreenBackground />
    </Wrapper>
  );
};

export default Information;
