import React from "react";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedIn.svg";
import {
  HeaderWrapper,
  IconsWrapper,
  Navigation,
  Wrapper,
} from "./Header.style";
import logo from "../../assets/icons/logo.png";
import Button from "../../shared/button/Button";
import { isMobile } from "react-device-detect";
interface HeaderProps {
  handleJoin: () => void;
}

const Header = ({ handleJoin }: HeaderProps) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <img style={{ width: "182px" }} src={logo} alt="" />
        <Navigation>
          <IconsWrapper>
            <a
              target="_blank"
              href="https://www.facebook.com/freebusinessgeorgia"
            >
              <Facebook />
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/company/105769129"
            >
              <LinkedIn />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/free_business_georgia"
            >
              <Instagram />
            </a>
          </IconsWrapper>
          {/* {!isMobile && (
            <Button
              onClick={handleJoin}
              size="small"
              type="green"
              content="შეუერთდი"
            />
          )} */}
        </Navigation>
      </HeaderWrapper>
    </Wrapper>
  );
};

export default Header;
