import styled from "styled-components";

export const Box = styled.div`
  position: relative;
`;

export const SearchWrapper = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  left: 16px;
  top: 15px;
`;

export const CustomInput = styled.input`
  /* TODO */
  font-size: 11px;
  font-weight: 500;
  line-height: 13.43px;

  width: 100%;
  border: none;
  padding: 21px 16px 21px 45px;
  border-radius: 12px;
  outline: none;
  z-index: 1;

  background-color: #65656514;
  color: black;
  &::placeholder {
    color: #989898;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  }
`;

export const RemoveIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-color: #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
