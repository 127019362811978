import styled, { css } from "styled-components";

export const Card = styled.div<{ haveUrl: boolean }>(
  ({ haveUrl }) => css`
    border: 1px solid #cdf1dc;
    display: flex;
    width: 100%;
    height: 226px;
    border-radius: 12px;
    align-items: center;
    position: relative;
    background: linear-gradient(180deg, #f0fff5 0%, #f8fffa 100%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    ${haveUrl &&
    css`
      cursor: pointer;
    `}
  `
);

export const NeutralCard = styled.div<{ haveUrl: boolean }>(
  ({ haveUrl }) => css`
    ${haveUrl &&
    css`
      cursor: pointer;
    `}
    border: 1px solid #ededed;
    display: flex;
    width: 100%;
    height: 226px;
    border-radius: 12px;
    align-items: center;
    position: relative;
    background: linear-gradient(180deg, #fcfcfc 0%, #fcfcfc 100%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `
);

export const TheirCard = styled.div<{ haveUrl: boolean }>(
  ({ haveUrl }) => css`
    ${haveUrl &&
    css`
      cursor: pointer;
    `}
    border: 1px solid #fed3dc;
    display: flex;
    width: 100%;
    height: 226px;
    border-radius: 12px;
    align-items: center;
    position: relative;
    background: linear-gradient(180deg, #fff2f4 0%, #fff9fa 100%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `
);

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
`;

export const StatusContainer = styled.div`
  width: fit-content;
  background-color: #cdf1dc;
  padding: 6px 14px;
  color: #1a9f41;
  border-radius: 0 0 10px 10px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const NeutralStatusContainer = styled.div`
  width: fit-content;
  background-color: #ededed;
  padding: 6px 14px;
  color: #7a7a7a;
  border-radius: 0 0 10px 10px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const TheirStatusContainer = styled.div`
  width: fit-content;
  background-color: #fdd3dc;
  padding: 6px 14px;
  color: #df1c41;
  border-radius: 0 0 10px 10px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const Image = styled.img`
  max-width: 148px;
  max-height: 50px;
`;

export const Title = styled.div`
  font-family: Helvetica Neue LT GEO;
  font-size: 18px;
  line-height: 16px;
`;

export const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;
export const Link = styled.a`
  width: 100%;
  height: 100%;
`;
