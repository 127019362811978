import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ type, isopen }) => css`
    cursor: pointer;
    width: fit-content;
    position: relative;
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    background-color: ${type === "basic" ? "#6565651f" : "inherit"};
    ${isopen &&
    type !== "outline" &&
    css`
      box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 3px #5154c01f;
    `}
  `
);

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const Title = styled.p(
  ({ type }) => css`
    font-family: "HelveticaNeue-normal", Arial, sans-serif;
    color: ${type === "basic" ? "#242424" : "#7A7A7A"};
    font-size: 13px;
    font-weight: 500;
    line-height: 15.87px;
  `
);

export const OptionsWrapper = styled.div(
  ({ position }) => css`
    position: absolute;
    top: 36px;
    ${position === "right"
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}

    width: 187px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    z-index: 100000;
    overflow: hidden;
  `
);

export const OptionsList = styled.div`
  overflow: auto;
  padding: 8px 0 0;
  max-height: 180px;
`;

export const OptionsItemWrapper = styled.div`
  cursor: pointer;
  padding-left: 16px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #65656514;
  }
`;

export const OptionsItem = styled.div`
  ${(props) =>
    !props.lastitem &&
    css`
      border-bottom: 1px solid #65656514;
    `}
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
  padding: 12px 24px 12px 0;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
