import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  background-color: #f7f7f7;
  padding: 0 45px 54px;
  border-radius: 12px;
`;
export const Header = styled.div`
  width: 111px;
  height: 26px;
  background-color: #efefef;
  border-radius: 0px 0px 12px 12px;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Image = styled.div`
  max-width: 216px;
  width: 100%;
  background-color: #efefef;
  height: 64px;
  border-radius: 12px;
`;
export const Title = styled.div`
  width: 140px;
  height: 22px;
  background-color: #efefef;
  border-radius: 4px;
  margin: auto;
`;
