import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: #fff;

  border-top-right-radius: ${isMobile ? "20px" : "64px"};
  border-top-left-radius: ${isMobile ? "20px" : "64px"};

  z-index: 10;
`;
export const Wrapper = styled.div`
  padding: 0 24px;
  width: 100%;
  max-width: 1300px;
  display: grid;
  margin: 0 auto;
  padding-top: ${isMobile ? "24px" : "68px"};
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: ${isMobile ? "column" : "row"};
  gap: ${isMobile ? "12px" : "24px"};
`;

export const Grid = styled.div`
  margin-top: 68px;
  margin-bottom: 70px;
  padding: 0 24px;
  width: 100%;

  max-width: 1300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${isMobile ? "16px" : "24px"};
  justify-self: center;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${isMobile ? "72px" : "92px"};
`;

export const SearchTitle = styled.div`
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  text-align: center;
  font-size: ${isMobile ? "18px" : "20px"};
  line-height: ${isMobile ? "21px" : "24px"};
  /*  */
`;

export const SearchDescription = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  line-height: 18px;
`;

export const LabelsContainer = styled.div`
  margin-top: ${isMobile ? "12px" : "16px"};
  padding: 4px;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const LabelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const LabelColorGreen = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 4px;
  background-color: #1a9f41;
`;
export const LabelColorGray = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 4px;
  background-color: #7a7a7a;
`;
export const LabelColorRed = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 4px;
  background-color: #df1c41;
`;

export const LabelText = styled.div`
  font-size: ${isMobile ? "9px" : "10px"};
  line-height: ${isMobile ? "10px" : "12px"};
`;
export const FilterWrapper = styled.div`
  max-width: 856px;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DropDownWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

export const NotSupportText = styled.div`
  font-size: 18px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
`;

export const NoDataRapper = styled.div`
  max-width: 308px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  align-items: center;
  margin: 100px auto;
`;

export const NoDataTitle = styled.p`
  /* TODO fonts */
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  color: #383838;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.87px;
`;

export const NoDataSubTitle = styled.p`
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: #989898;
`;

export const NoDataContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const BusinessFilterWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const InfoText = styled.div`
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
`;
