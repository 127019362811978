import styled from "styled-components";

interface WrapperProps {
  widthType?: "full" | "fit-content";
  type?: "green" | "white";
  size?: "normal" | "small";
}

export const Wrapper = styled.button<WrapperProps>`
  padding: ${(props) => (props.size === "normal" ? "18px 40px" : "10px 24px")};
  border-radius: 40px;
  width: ${(props) => (props.widthType === "full" ? "100%" : "fit-content")};
  border: none;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;

  color: ${(props) => (props.type === "green" ? "#FFFFFF" : "#323232")};
  background-color: ${(props) =>
    props.type === "green" ? "#41AD49" : "#FFFFFF"};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.type === "green" ? "#4BBF54" : "#FAFAFA"};
    color: ${(props) => (props.type === "green" ? "#FFFFFF" : "#323232")};
  }
`;
