import styled from "styled-components";

export const HeaderWrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

export const Wrapper = styled.header`
  background-color: white;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px;
  display: flex;
  justify-content: center;
`;
export const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  & svg {
    cursor: pointer;
  }
`;
