import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  display: flex;
  flex-direction: ${isMobile ? "row" : "column"};
  gap: ${isMobile ? "16px" : "24px"};
  flex: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 12px;
  background-color: #eeeeee;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: ${isMobile ? "12px" : "18px"};
  line-height: ${isMobile ? "14px" : "22px"};
  font-weight: 400;
`;

export const Quantity = styled.div`
  font-size: ${isMobile ? "22px" : "42px"};
  line-height: ${isMobile ? "26px" : "51px"};
  font-weight: 700;
  font-family: "HelveticaNeue", Arial, sans-serif;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${isMobile ? "2px" : "8px"};
`;

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fef7e0;
  border-radius: 12px;
  padding: 12px 8px;
  font-family: sans-serif;
  flex-wrap: wrap;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  flex-direction: column;
  padding-left: 6px;
`;

export const GrayText = styled.span`
  color: #797979;
  font-size: 12px;
  padding-left: 2px;
`;

export const BlackText = styled.span`
  color: #000000;
  font-size: 12px;
  font-weight: bold;
`;
