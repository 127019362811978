import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 182px;
  padding-bottom: 80px;
  gap: ${isMobile ? "24px" : "32px"};

  @media (max-width: 1000px) {
    padding: 150px 24px;
  }
  @media (max-width: 660px) {
    padding: 123px 24px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 862px;
  width: 100%;
  z-index: 100;
  align-items: center;
`;
// TODO
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${isMobile && "width: 85px;"}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// TODO
export const Title = styled.h5`
  font-size: ${isMobile ? "24px !important" : "48px"};
  font-weight: 400;
  line-height: ${isMobile ? "28px !important" : "57px"};
  color: #323232;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  @media (max-width: 1000px) {
    font-size: 35px;
    line-height: 40px;
  }
`;
export const SubTitle = styled.h3`
  font-size: ${isMobile ? "24px !important" : "64px"};
  font-weight: 700;
  line-height: ${isMobile ? "29px !important" : "96px"};
  color: #323232;
  font-family: "HelveticaNeue", Arial, sans-serif;
  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 80px;
  }
`;
export const Description = styled.p`
  font-size: ${isMobile ? "14px !important" : "18px"};
  font-weight: 400;
  line-height: ${isMobile ? "22px !important" : "18px"};
  margin-top: 12px;
  color: #7f7f7f;
  line-height: 25px;

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const WarningText = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #fef7e0;
  border-radius: 12px;
  padding: 12px 8px;
  font-family: sans-serif;
  margin-top: 24px;
`;

export const BlackText = styled.span`
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  padding-left: 6px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 100;
  ${isMobile && "flex-direction: column;"}
`;

export const EuropeFlagWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  & img {
    width: ${isMobile && "100px"};
  }
`;
export const GeorgianFlagWrapper = styled.div`
  position: absolute;
  bottom: ${isMobile ? "-40px" : "-130px"};
  left: ${isMobile ? "-10px" : "0"};
  & img {
    width: ${isMobile && "100px"};
  }
`;

export const GeorgianFlagBackground = styled.div`
  position: absolute;
  background-color: #e31d1c1a;
  filter: blur(50px);
  border-radius: 50%;
  transform: rotate(19.17deg);
  width: 1007px;
  height: 1007px;
  bottom: ${isMobile ? "-560px" : "-350px"};
  left: ${isMobile ? "-760px" : "-630px"};
  z-index: 9;
`;
export const GreenBackground = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  width: ${isMobile ? "390px" : "1048px"};
  height: ${isMobile ? "390px" : "1048px"};
  bottom: ${isMobile ? "-50px" : "-800px"};
  left: ${isMobile ? "50%" : "20%"};
  background-color: #41ad491a;
  transform: rotate(19.17deg);
`;

export const EuropeFlagBackground = styled.div`
  z-index: -1;
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  width: 1048.6px;
  height: 1048.6px;
  top: ${isMobile ? "-630px" : "-430px"};
  right: ${isMobile ? "-830px" : "-600px"};
  background-color: #00339929;
  transform: rotate(19.17deg);
`;
