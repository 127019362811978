import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import {
  IconWrapper,
  OptionsItem,
  OptionsItemWrapper,
  OptionsList,
  OptionsWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "./Dropdown.style";

const Dropdown = ({
  optionsPosition,
  type = "basic",
  title,
  selected,
  options,
  handleSelect,
  icon,
}) => {
  const [opened, setOpened] = useState(false);
  const dropDownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef?.current && !dropDownRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selected, options]);

  return (
    <Wrapper
      type={type}
      ref={dropDownRef}
      onClick={() => setOpened((prev) => !prev)}
      isopen={opened}
    >
      {icon}
      <TitleWrapper>
        <Title type={type}>{title}</Title>
      </TitleWrapper>
      <IconWrapper className={opened ? "rotate" : ""}>
        <ArrowDownIcon />
      </IconWrapper>
      {opened && (
        <OptionsWrapper position={optionsPosition}>
          <OptionsList>
            {options.map((item, index) => (
              <OptionsItemWrapper
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpened((prev) => !prev);
                  handleSelect(item.id);
                }}
              >
                <OptionsItem lastitem={options.length - 1 === index}>
                  {item.title}
                  {selected === item.id && <CheckIcon />}
                </OptionsItem>
              </OptionsItemWrapper>
            ))}
          </OptionsList>
        </OptionsWrapper>
      )}
    </Wrapper>
  );
};

export default Dropdown;
