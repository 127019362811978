import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove.svg";
import {
  Box,
  CustomInput,
  SearchWrapper,
  RemoveIconWrapper,
} from "./SearchInput.style";
import React from "react";

const SearchInput = ({ placeholder, value, handleChange }: any) => {
  return (
    <Box>
      <SearchWrapper>
        <SearchIcon />
      </SearchWrapper>
      <CustomInput
        id="CompanySearch"
        placeholder={placeholder}
        value={value}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        onChange={(e: any) => handleChange(e.target.value)}
      />
      {Boolean(value.length) && (
        <RemoveIconWrapper onClick={() => handleChange("")}>
          <RemoveIcon />
        </RemoveIconWrapper>
      )}
    </Box>
  );
};

export default SearchInput;
