import { useState } from "react";

import { Box, CustomInput, ErrorMassage, Label } from "./Input.style";

const Input = ({ field, form: { touched, errors }, label, ...props }) => {
  const [focused, setFocused] = useState(false);
  const handleBlur = () => setFocused(false);
  const handleFocus = () => setFocused(true);

  const isInvalid = Boolean(touched[field.name] && errors[field.name]);
  const hasValue = Boolean(field.value);

  return (
    <Box onBlur={handleBlur} onFocus={handleFocus}>
      <Label
        invalid={isInvalid}
        htmlFor={field.name}
        isfocus={focused}
        hasValue={hasValue}
      >
        {label}
      </Label>
      <CustomInput
        {...field}
        {...props}
        id={field.name}
        hasValue={hasValue}
        isfocused={focused}
        invalid={isInvalid}
      />
      {isInvalid && <ErrorMassage>გთხოვთ შეავსოთ ველი</ErrorMassage>}
    </Box>
  );
};

export default Input;
