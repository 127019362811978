import React from "react";

import {
  ContentWrapper,
  Header,
  Title,
  Wrapper,
  Image,
} from "./GridSkeleton.style";

const GridSkeleton = () => {
  return (
    <Wrapper>
      <Header />
      <ContentWrapper>
        <Image />
        <Title />
      </ContentWrapper>
    </Wrapper>
  );
};

export default GridSkeleton;
