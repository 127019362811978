import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Wrapper,
  InfoContainer,
  Grid,
  Container,
  SearchContainer,
  SearchTitle,
  SearchDescription,
  LabelsContainer,
  LabelItem,
  LabelColorGreen,
  LabelText,
  LabelColorGray,
  LabelColorRed,
  FilterWrapper,
  DropDownWrapper,
  NoDataRapper,
  NoDataTitle,
  NoDataSubTitle,
  NoDataContent,
  NotSupportText,
  BusinessFilterWrapper,
  InfoText,
} from "./GridSection.style";
import CardComponent from "../cardComponent/CardComponent";
import SupportCard from "../supportCard/SupportCard";
import SearchInput from "../searchInput/SearchInput";
import Dropdown from "../../shared/dropdown/Dropdown";
import Button from "../../shared/button/Button";
import { ReactComponent as Support } from "../../assets/icons/support.svg";
import { ReactComponent as Thinking } from "../../assets/icons/thinking.svg";
import { ReactComponent as People } from "../../assets/icons/people.svg";

// import { ReactComponent as NotSupport } from "../../assets/icons/notSupport.svg";
import { ReactComponent as Briefcase } from "../../assets/icons/brifcase.svg";
import { ReactComponent as NoDataIcon } from "../../assets/icons/noData.svg";
import refreshImg from "../../assets/icons/refresh.jpeg";

import GridSkeleton from "../gridSkeleton/GridSkeleton";

const sortDropdownOptions = [
  { title: "სტანდარტული", id: 1 },
  { title: "ახლიდან ძველისკენ", id: 2 },
  { title: "ძველიდან ახლისკენ", id: 3 },
];

const positionDropdownOptions = [
  { title: "მხარდამჭერი", id: 1 },
  { title: "ჯერ არ დაუფიქსირებია", id: 3 },
  { title: "არ შემოუერთდა", id: 2 },
];

interface IProps {
  handleJoin: () => void;
}

type Status = 1 | 2 | 3;
interface Business {
  businessName: string;
  businessNameEn: string;
  freeStatus: Status;
  identificationCode: string | null;
  logoEndPointUrl: string;
  socialPlatformUrl: string | null;
  id: number;
}

const GridSection = ({ handleJoin }: IProps) => {
  const [data, setData] = useState<Array<Business>>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [busyMeetingValue, setBusyMeetingValue] = useState("");
  const [sortedValue, setSortedValue] = useState(1);
  const [fieldOptions, setFieldOptions] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [ourCount, setOurCount] = useState(0);
  const [neutralCount, setNeutralCount] = useState(0);
  const [theirCount, setTheirCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [positionValue, setPositionValue] = useState(1);

  const fetchData = async ({
    pageNumber = 1,
    search = "",
    businessFieldOfActivityId = "",
    sortBy = "",
    sortDirection = "",
    businessFreeStatus = 1,
  }) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.freebusiness.ge/Core/Business/GetAllV1?page=${pageNumber}&pageSize=20&search=${search}&businessFieldOfActivityId=${businessFieldOfActivityId}&sortBy=${sortBy}&sortDirection=${sortDirection}&businessFreeStatus=${businessFreeStatus}`
      );
      if (!response.ok) throw new Error("Failed to fetch data");

      const result = await response.json();

      // Set business fields for dropdown on first load
      if (pageNumber === 1 && result.businessFieldOfActivities) {
        setFieldOptions(result.businessFieldOfActivities);
      }

      setOurCount(result.ourCount);
      setNeutralCount(result.neutralCount);
      setTheirCount(result.theirCount);
      setInProgressCount(result.inProgressCount);
      // Append new paged data
      const newItems = result.pagedBusinesses.items;

      setData((prevData) =>
        pageNumber === 1 ? newItems : [...prevData, ...newItems]
      );

      // Determine if there are more pages
      setHasMore(newItems.length === result.pagedBusinesses.pageSize);
      setLoading(false);
    } catch (err: any) {
      console.error(err.message);
    } finally {
    }
  };

  const handleFilter = (value: string) => {
    setPage(1);
    const sortDirection =
      sortedValue === 2 ? "Desc" : sortedValue === 3 ? "Asc" : "";
    const sortBy = sortedValue === 1 ? "" : "CreatedAt";
    if (busyMeetingValue !== value) {
      setBusyMeetingValue(value);
      fetchData({
        pageNumber: 1,
        search: "",
        businessFieldOfActivityId: value,
        sortBy,
        sortDirection,
        businessFreeStatus: positionValue,
      });
    } else {
      setBusyMeetingValue("");
      fetchData({
        pageNumber: 1,
        search: "",
        businessFieldOfActivityId: "",
        sortBy,
        sortDirection,
        businessFreeStatus: positionValue,
      });
    }
  };

  const handlePositionFilter = (value: number) => {
    setPositionValue(value);
    setPage(1);
    const sortDirection =
      sortedValue === 2 ? "Desc" : sortedValue === 3 ? "Asc" : "";
    const sortBy = sortedValue === 1 ? "" : "CreatedAt";
    fetchData({
      pageNumber: 1,
      search: "",
      businessFieldOfActivityId: busyMeetingValue,
      sortBy,
      sortDirection,
      businessFreeStatus: value,
    });
  };

  const handleSortData = (value: number) => {
    setPage(1);

    if (sortedValue !== value) {
      setSortedValue(value);
      const sortDirection = value === 2 ? "Desc" : value === 3 ? "Asc" : "";
      const sortBy = value === 1 ? "" : "CreatedAt";

      fetchData({
        pageNumber: 1,
        search: "",
        businessFieldOfActivityId: busyMeetingValue,
        sortBy,
        sortDirection,
        businessFreeStatus: positionValue,
      });
    } else {
      return;
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData({
        pageNumber: 1,
        search: searchValue,
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const loadMoreData = () => {
    const nextPage = page + 1;
    const sortBy = sortedValue === 1 ? "" : "CreatedAt";
    const sortDirection =
      sortedValue === 2 ? "Desc" : sortedValue === 3 ? "Asc" : "";

    setPage(nextPage);
    fetchData({
      pageNumber: nextPage,
      search: searchValue,
      businessFieldOfActivityId: busyMeetingValue,
      sortBy,
      sortDirection,
      businessFreeStatus: positionValue,
    });
  };

  return (
    <Container>
      <Wrapper>
        {/* Static Info Section */}
        <InfoContainer>
          {/* <CardComponent
            icon={
              <img src={refreshImg} height={24} width={24} alt="inProgress" />
            }
            title="მუშავდება"
            quantity={inProgressCount}
          /> */}
          <CardComponent
            icon={<Support />}
            isActive={positionValue === 1}
            onClick={() => {
              handlePositionFilter(1);
            }}
            title="მხარდამჭერი"
            quantity={ourCount}
          />
          {/* <CardComponent
            icon={<Thinking />}
            onClick={() => {
              handlePositionFilter(3);
            }}
            isActive={positionValue === 3}
            title="ჯერ არ დაუფიქსირებია"
            quantity={neutralCount}
          /> */}
          <CardComponent
            icon={<NotSupportText>❌</NotSupportText>}
            title="არ შემოუერთდა"
            quantity={theirCount}
            isActive={positionValue === 2}
            onClick={() => {
              handlePositionFilter(2);
            }}
          />
        </InfoContainer>

        {/* Search and Filter */}
        <SearchContainer>
          <SearchTitle>კომპანიების ჩამონათვალი</SearchTitle>
          <SearchDescription>
            თქვენ აქ იხილავთ იმ კომპანიებს რომელთა პოზიციაც დაფიქსირებულია ჩვენი
            მიზნის მიმართ
          </SearchDescription>
          <LabelsContainer>
            <LabelItem>
              <LabelColorGreen />
              <LabelText>მხარდამჭერი</LabelText>
            </LabelItem>
            {/* <LabelItem>
              <LabelColorGray />
              <LabelText>ჯერ არ დაუფიქსირებია</LabelText>
            </LabelItem> */}
            <LabelItem>
              <LabelColorRed />
              <LabelText>არ შემოუერთდა</LabelText>
            </LabelItem>
          </LabelsContainer>
          <FilterWrapper>
            <SearchInput
              placeholder={"მოიძებნეთ კომპანია"}
              value={searchValue}
              handleChange={(value: string) => {
                setSearchValue(value);
                setPage(1);
                setHasMore(true);
              }}
            />
            <DropDownWrapper>
              <BusinessFilterWrapper>
                <Dropdown
                  optionsPosition="right"
                  icon={<People />}
                  title="პოზიცია"
                  selected={positionValue}
                  handleSelect={handlePositionFilter}
                  options={positionDropdownOptions}
                />
                <Dropdown
                  optionsPosition="right"
                  icon={<Briefcase />}
                  title="საქმიანობის სფერო"
                  selected={busyMeetingValue}
                  handleSelect={handleFilter}
                  options={[
                    { title: "ყველა", id: "" },
                    ...fieldOptions?.map((item) => ({
                      title: item.name,
                      id: item.id,
                    })),
                  ]}
                />
              </BusinessFilterWrapper>
              <Dropdown
                optionsPosition="left"
                type="outline"
                icon={null}
                title={
                  sortDropdownOptions?.find((item) => item.id === sortedValue)
                    ?.title || ""
                }
                selected={sortedValue}
                handleSelect={handleSortData}
                options={sortDropdownOptions}
              />
            </DropDownWrapper>
          </FilterWrapper>
        </SearchContainer>

        {positionValue === 3 && (
          <InfoText>
            პლატფორმაზე ეტაპობრივად დაემატება ყველაზე მოთხოვნადი კომპანიები
          </InfoText>
        )}

        {/* Infinite Scroll Grid */}
        <div style={{ minHeight: "684px" }}>
          <InfiniteScroll
            style={{ margin: 0 }}
            dataLength={data.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={
              <Grid>
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
              </Grid>
            }
          >
            {Boolean(data.length) && (
              <Grid>
                {data.map((item, index) => (
                  <SupportCard
                    id={item.id}
                    url={item.socialPlatformUrl}
                    key={index}
                    status={item.freeStatus}
                    businessName={item.businessName}
                    logoEndPointUrl={item.logoEndPointUrl || ""}
                  />
                ))}
              </Grid>
            )}
          </InfiniteScroll>

          {/* No Data */}
          {!data.length && !loading && (
            <NoDataRapper>
              <NoDataIcon />
              <NoDataContent>
                <NoDataTitle>კომპანია არ მოიძებნა</NoDataTitle>
                {/* <NoDataSubTitle>
                  კომპანია ბაზაში არ მოიძებნა, შეგიძლიათ მოითხოვოთ კომპანიის
                  მიერ პოზიციის დაფიქსირება
                </NoDataSubTitle> */}
              </NoDataContent>
              {/* <Button
                widthType="full"
                isLoading={loading}
                type="green"
                content="შეუერთდი"
                onClick={handleJoin}
              /> */}
            </NoDataRapper>
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

export default GridSection;
