import React, { useState } from "react";
import { ReactComponent as ExternalLink } from "../../assets/icons/externalLink.svg";
import {
  Card,
  NeutralCard,
  TheirCard,
  Header,
  StatusContainer,
  NeutralStatusContainer,
  TheirStatusContainer,
  Image,
  Title,
  IconWrapper,
  Link,
} from "./SupportCard.style";
import { isMobile } from "react-device-detect";

const Status = {
  OUR: 1,
  THEIR: 2,
  NEUTRAL: 3,
} as const;
type StatusValue = (typeof Status)[keyof typeof Status];

interface IProps {
  status: StatusValue;
  logoEndPointUrl: string;
  businessName: string;
  url: string | null;
  id: number;
}

const SupportCard = ({
  status,
  logoEndPointUrl,
  businessName,
  id,
  url,
}: IProps) => {
  const [isHover, setIsHover] = useState<number | null>(null);

  const isIconShown =
    (isMobile && Boolean(url)) || (isHover === id && Boolean(url));

  const getCardComponents = () => {
    switch (status) {
      case Status.OUR:
        return {
          Container: Card,
          StatusComponent: StatusContainer,
          statusText: "მხარდამჭერი",
        };
      case Status.NEUTRAL:
        return {
          Container: NeutralCard,
          StatusComponent: NeutralStatusContainer,
          statusText: "არ დაუფიქსირებია",
        };
      case Status.THEIR:
      default:
        return {
          Container: TheirCard,
          StatusComponent: TheirStatusContainer,
          statusText: "არ შემოუერთდა",
        };
    }
  };

  const { Container, StatusComponent, statusText } = getCardComponents();

  return (
    <Container
      onMouseEnter={() => setIsHover(id)}
      onMouseLeave={() => setIsHover(null)}
      haveUrl={Boolean(url)}
    >
      {url ? (
        <Link target="_blank" href={url}>
          <Header>
            <StatusComponent>{statusText}</StatusComponent>
            <Image src={`${logoEndPointUrl}?nocache=${new Date().getTime()}`} />
          </Header>
          <Title>{businessName}</Title>
          {isIconShown && (
            <IconWrapper>
              <ExternalLink />
            </IconWrapper>
          )}
        </Link>
      ) : (
        <>
          <Header>
            <StatusComponent>{statusText}</StatusComponent>
            <Image src={`${logoEndPointUrl}?nocache=${new Date().getTime()}`} />
          </Header>
          <Title>{businessName}</Title>
        </>
      )}
    </Container>
  );
};

export default SupportCard;
